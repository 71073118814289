import React, { useState } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { estados } from "../../assets/cidades.json";
import { useForm } from "react-hook-form";

import ContainerDefault from "../../components/container";

import api from "../../services/api";

const Container = styled.div`
  form {
    padding-top: 26px;
    padding-bottom: 35px;
  }

  .input-text,
  .input-select {
    margin-bottom: 23px;
  }

  .input-error {
    border-bottom: 1px solid #bd0303;
    color: #bd0303;

    &::placeholder {
      color: #bd0303;
    }
  }
  .input-error-area {
    border: 1px solid #bd0303;
    color: #bd0303;

    &::placeholder {
      color: #bd0303;
    }
  }

  .password-section {
    p.error {
      color: #bd0303;
      font-size: 14px;
    }
  }

  .form-errors {
    background: #eba7a7;
    padding: 10px 0;
    text-align: center;

    ul li {
      font: italic bold 14px "Times new roman";
      color: #bd0000;

      display: flex;
      align-items: center;
      justify-content: center;

      i {
        width: 31px;
        height: 31px;
        background: url("/images/streamline-icon-alert-diamond-140-x-140.png")
          no-repeat center;
        background-size: contain;
        display: block;
        margin-right: 11px;
      }
    }
  }

  .half-mobile {
    display: flex;
    justify-content: space-between;

    input,
    select {
      width: 48%;
    }
  }

  .picture {
    padding-top: 12px;
    text-align: center;
    margin-bottom: 35px;

    > h3 {
      font: italic bold 22px "Times new roman";
      color: #000000;
      margin-bottom: 35px;
    }

    .image-profile {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      display: block;
      margin: 0 auto 35px;
    }

    .img-profile-empty {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin: 0 auto 35px;

      background: #ffffff
        url("/images/streamline-icon-paginate-filter-picture-140-x-140.png")
        no-repeat center;
      background-size: 40px;
    }

    #picture-file {
      display: none;
    }

    .button-file {
      cursor: pointer;
      max-width: 280px;
      height: 50px;
      border-radius: 3px;
      border: 3px solid #f1a857;
      margin: 0 auto;

      display: flex;
      align-items: center;

      transition: 0.2s all ease;

      span {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        padding-left: 35px;
        padding-right: 15px;

        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      i {
        width: 57px;
        height: 100%;
        border-left: 1px solid #f1a857;
        margin-left: auto;
        background: url("/images/streamline-icon-image-file-upload-140-x-140.png")
          center no-repeat;
        background-size: 24px;
      }

      &:hover {
        background: #f1a857;

        span {
          color: #ffffff;
        }

        i {
          border-left: 1px solid #e0933d;
        }
      }
    }
  }

  .terms-field {
    margin: 35px 0;

    p.error {
      color: #bd0303;
      font-size: 14px;
      text-align: center;
    }
  }

  .ready-terms {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    color: #000000;
    margin-bottom: 10px;

    input {
      border: 2px solid #979797;
      width: 26px;
      height: 26px;
      background: transparent;
      margin-right: 6px;
      appearance: none;
      border-radius: 3px;

      &:checked {
        border: 2px solid #ffffff;
        background: url(/images/check.svg) no-repeat center #fff;
      }
    }

    a {
      font-weight: 700;
      text-decoration: underline;
      color: #000000;
    }
  }

  .button-submit {
    cursor: pointer;
    max-width: 300px;
    width: 100%;
    height: 60px;
    border-radius: 3px;
    border: 0;
    margin: 0 auto;
    background: #f1a857;

    display: flex;
    align-items: center;

    transition: 0.2s all ease;

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      padding-left: 35px;
      padding-right: 30px;

      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    i {
      width: 73px;
      height: 100%;
      border-left: 1px solid #d99440;
      margin-left: auto;
      background: url("/images/streamline-icon-send-email-140-x-140.png") center
        no-repeat;
      background-size: 24px;
    }

    &:hover {
      background: #e59134;

      span {
        color: #ffffff;
      }

      i {
        border-left: 1px solid #cd8226;
      }
    }
  }

  .section-fields {
    margin-bottom: 40px;

    > h3 {
      font: italic bold 30px "Times new roman";
      color: #000000;
      margin-bottom: 12px;
    }

    p {
      font-size: 14px;
      color: #000000;
      margin-bottom: 25px;
    }

    &.last-section {
      margin-bottom: 0;
    }
  }

  @media (min-width: 767px) {
    form {
      padding-top: 40px;
      padding-bottom: 50px;
    }

    .input-text,
    .input-select {
      margin-bottom: 40px;
    }

    .half {
      display: flex;
      justify-content: space-between;

      input,
      select {
        width: 48%;
      }
    }

    .half-two {
      display: flex;
      justify-content: space-between;

      input {
        width: 48%;
      }
      & > div {
        width: 48%;
      }
    }

    .half-picture {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      & > div {
        width: 44%;
      }
      textarea {
        width: 54%;
      }
    }

    .password-section {
      p.error {
        padding-bottom: 20px;
      }

      .input-text {
        margin-bottom: 20px;
      }
    }

    .picture {
      margin-bottom: 0;
    }

    .terms-field {
      margin: 70px 0 40px;
    }

    .ready-terms {
      justify-content: center;

      font-size: 16px;
    }

    .button-submit {
      max-width: 380px;

      i {
        width: 83px;
      }
    }
  }

  @media (min-width: 1200px) {
    form {
      padding-top: 40px;
      padding-bottom: 80px;
      max-width: 962px;
      margin: 0 auto;
    }

    .half {
      input,
      select {
        width: 48.5%;
      }
    }

    .half-two {
      input {
        width: 48.5%;
      }
      & > div {
        width: 48.5%;
      }
    }

    .half-picture {
      & > div {
        width: 48.5%;
      }
      textarea {
        width: 48.5%;
      }
    }

    .terms-field {
      margin: 70px 0 65px;
    }
  }
`;

const showMask = ({ target }) => {
  console.log(target.getAttribute("name"));
  let splittedvalue = target.value.split(".com/");
  if (splittedvalue.length < 2) {
    splittedvalue[1] = "";
  }
  switch (target.getAttribute("name")) {
    case "companyFacebook":
      target.value = `facebook.com/${splittedvalue[1]}`;
      break;

    case "companyInstagram":
      target.value = `instagram.com/${splittedvalue[1]}`;
      break;

    case "companyPinterest":
      target.value = `pinterest.com/${splittedvalue[1]}`;
      break;

    case "companyLinkedin":
      target.value = `linkedin.com/${splittedvalue[1]}`;
      break;

    default:
      break;
  }
};

export default function RegisterFields() {
  const [cidades, setCidades] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [profileImage, setProfileImage] = useState(null);

  const { register, handleSubmit, errors, getValues } = useForm();

  async function handleSendForm(data) {
    const dataForm = {
      name: data.name,
      surname: data.lastName,
      email: data.email,
      pwd: data.password,
      profession: data.profession,
      phone: data.phone,
      uf: data.state,
      city: data.city,
      background: "",
      company_name: data.companyName,
      company_phone: data.companyPhone,
      company_email: data.companyEmail,
      company_facebook: data.companyFacebook,
      company_instagram: data.companyInstagram,
      company_pinterest: data.companyPinterest,
      company_linkedin: data.companyLinkedin,
      company_website: data.companyWebsite,
      company_avatar: profileImage ? profileImage : "",
      company_about: data.companyAbout,
    };

    await api
      .post("https://apimeuambiente.guararapes.com.br/user/create/", dataForm)
      .then(function (res) {
        alert("Usuário criado com sucesso");
        navigate("/");
      })
      .catch(function () {
        alert(
          "Esse email já foi cadastrado. Faça login em sua conta ou utilize outro email para concluir seu cadastro"
        );
      });
  }

  const toggleEstado = (e) => {
    estados.map((item) => {
      if (item.sigla.toLowerCase() === e.target.value) setCidades(item.cidades);
    });
  };

  async function handleSelectImages(event) {
    if (!event.target.files) {
      return;
    }

    const selectedImage = event.target.files[0];

    setPreviewImage(URL.createObjectURL(selectedImage));

    const data = new FormData();

    data.append("file[0]", event.target.files[0]);

    const response = await api.post(
      "https://apimeuambiente.guararapes.com.br/upload/",
      data
    );

    setProfileImage(response.data.imgs[0].url);
  }

  return (
    <Container>
      {Object.keys(errors).length > 1 && (
        <div className="form-errors">
          <ul>
            <li>
              <i></i> Preencha todos os campos corretamente. Verifique abaixo:
            </li>
          </ul>
        </div>
      )}

      <ContainerDefault>
        <form onSubmit={handleSubmit(handleSendForm)}>
          <div className="section-fields">
            <h3>Dados Pessoais</h3>
            <p>
              Esses dados são para cadastro e acesso a sua conta, e não serão
              divulgados.
            </p>

            <fieldset className="half">
              <input
                type="text"
                name="name"
                placeholder="Nome *"
                className={
                  errors.name ? "input-text input-error" : "input-text"
                }
                ref={register({ required: true })}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Sobrenome *"
                className={
                  errors.lastName ? "input-text input-error" : "input-text"
                }
                ref={register({ required: true })}
              />
            </fieldset>

            <fieldset className="half">
              <input
                type="email"
                name="email"
                placeholder="Email *"
                className={
                  errors.email ? "input-text input-error" : "input-text"
                }
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Email invalido",
                  },
                })}
              />
              <select
                name="profession"
                className={
                  errors.profession
                    ? "input-select input-error"
                    : "input-select"
                }
                ref={register({ required: true })}
              >
                <option value="">Profissão *</option>
                <option value="Arquiteto">Arquiteto</option>
                <option value="Designer de interiores">
                  Designer de interiores
                </option>
                <option value="Marceneiro">Marceneiro</option>
              </select>
            </fieldset>

            <fieldset className="half-two">
              <input
                type="text"
                name="phone"
                placeholder="Telefone (DDD) *"
                className={
                  errors.phone ? "input-text input-error" : "input-text"
                }
                ref={register({ required: true })}
              />

              <div className="half half-mobile">
                <select
                  onChange={toggleEstado}
                  name="state"
                  className={
                    errors.state ? "input-select input-error" : "input-select"
                  }
                  ref={register({ required: true })}
                >
                  <option value="">Estado *</option>
                  <option value="ac">Acre</option>
                  <option value="al">Alagoas</option>
                  <option value="am">Amazonas</option>
                  <option value="ap">Amapá</option>
                  <option value="ba">Bahia</option>
                  <option value="ce">Ceará</option>
                  <option value="df">Distrito Federal</option>
                  <option value="es">Espírito Santo</option>
                  <option value="go">Goiás</option>
                  <option value="ma">Maranhão</option>
                  <option value="mt">Mato Grosso</option>
                  <option value="ms">Mato Grosso do Sul</option>
                  <option value="mg">Minas Gerais</option>
                  <option value="pa">Pará</option>
                  <option value="pb">Paraíba</option>
                  <option value="pr">Paraná</option>
                  <option value="pe">Pernambuco</option>
                  <option value="pi">Piauí</option>
                  <option value="rj">Rio de Janeiro</option>
                  <option value="rn">Rio Grande do Norte</option>
                  <option value="ro">Rondônia</option>
                  <option value="rs">Rio Grande do Sul</option>
                  <option value="rr">Roraima</option>
                  <option value="sc">Santa Catarina</option>
                  <option value="se">Sergipe</option>
                  <option value="sp">São Paulo</option>
                  <option value="to">Tocantins</option>
                </select>
                <select
                  name="city"
                  className={
                    errors.city ? "input-select input-error" : "input-select"
                  }
                  ref={register({ required: true })}
                >
                  <option value="">Cidade *</option>
                  {cidades &&
                    cidades.map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      );
                    })}
                </select>
              </div>
            </fieldset>
          </div>

          <div className="section-fields password-section">
            <h3>Senha de acesso</h3>
            <p>Crie uma senha para acesso a sua conta.</p>

            <fieldset className="half">
              <input
                type="password"
                name="password"
                placeholder="Crie uma senha *"
                className={
                  errors.password ? "input-text input-error" : "input-text"
                }
                ref={register({ required: "Password is required!" })}
              />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirme sua senha *"
                className={
                  errors.confirmPassword
                    ? "input-text input-error"
                    : "input-text"
                }
                ref={register({
                  required: "Por favor, confirme sua senha!",
                  validate: {
                    matchesPreviousPassword: (value) => {
                      const { password } = getValues();
                      return password === value || "A senha não bate!";
                    },
                  },
                })}
              />
            </fieldset>
            {errors.confirmPassword && (
              <p className="error">{errors.confirmPassword.message}</p>
            )}
          </div>

          <div className="section-fields last-section">
            <h3>Dados profissionais</h3>
            <p>
              Essas informações serão divulgadas em seu perfil profissional.
            </p>

            <fieldset className="half half-mobile">
              <input
                type="text"
                name="companyName"
                placeholder="Nome do Escritório/Empresa"
                className="input-text"
                className={
                  errors.companyName ? "input-text input-error" : "input-text"
                }
                ref={register}
              />
              <input
                type="text"
                name="companyPhone"
                placeholder="Telefone"
                className="input-text"
                className={
                  errors.companyPhone ? "input-text input-error" : "input-text"
                }
                ref={register}
              />
            </fieldset>

            <fieldset className="half">
              <input
                type="email"
                name="companyEmail"
                placeholder="Email Profissional *"
                className="input-text"
                className={
                  errors.companyEmail ? "input-text input-error" : "input-text"
                }
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Email invalido",
                  },
                })}
              />
              <input
                type="text"
                placeholder="Link Website"
                className="input-text"
                name="companyWebsite"
                ref={register}
              />
            </fieldset>

            <fieldset className="half half-mobile">
              <input
                type="text"
                placeholder="Link do perfil no Facebook"
                className={
                  errors.companyFacebook
                    ? "input-error input-text"
                    : "input-text"
                }
                name="companyFacebook"
                ref={register({
                  required: false,
                  pattern: {
                    value: /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/,
                    message: "Informe a URL do seu perfil no Facebook",
                  },
                })}
                //onFocus={showMask}
              />
              <input
                type="text"
                placeholder="Link do perfil no Instagram"
                className={
                  errors.companyInstagram
                    ? "input-error input-text"
                    : "input-text"
                }
                name="companyInstagram"
                ref={register({
                  required: false,
                  pattern: {
                    value: /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9(\.\?)?]/,
                    message: "Informe a URL do seu perfil no Instagram",
                  },
                })}
                //onFocus={showMask}
              />
            </fieldset>

            <fieldset className="half half-mobile">
              <input
                type="text"
                placeholder="Link do perfil no Pinterest"
                className={
                  errors.companyPinterest
                    ? "input-error input-text"
                    : "input-text"
                }
                name="companyPinterest"
                ref={register({
                  required: false,
                  pattern: {
                    value: /^(https?:\/\/)?(www\.)?br.pinterest.com\/[a-zA-Z0-9(\.\?)?]/,
                    message: "Informe a URL do seu perfil no Pinterest",
                  },
                })}
                //onFocus={showMask}
              />
              <input
                type="text"
                placeholder="Link do perfil no Linkedin"
                className={
                  errors.companyLinkedin
                    ? "input-error input-text"
                    : "input-text"
                }
                name="companyLinkedin"
                ref={register({
                  required: false,
                  pattern: {
                    value: /^(https?:\/\/)?(www\.)?linkedin.com\/[a-zA-Z0-9(\.\?)?]/,
                    message: "Informe a URL do seu perfil no Linkedin",
                  },
                })}
                //onFocus={showMask}
              />
            </fieldset>

            <fieldset className="half-picture">
              <div className="picture">
                <h3>Foto ou logomarca de perfil</h3>
                {previewImage ? (
                  <span
                    class="image-profile"
                    style={{ backgroundImage: `url(${previewImage})` }}
                  ></span>
                ) : (
                  <div className="img-profile-empty"></div>
                )}
                <label htmlFor="picture-file" className="button-file">
                  <input
                    type="file"
                    name=""
                    id="picture-file"
                    onChange={handleSelectImages}
                  />
                  <span>Selecionar imagem</span>
                  <i></i>
                </label>
              </div>
              <textarea
                placeholder="Sobre a Empresa *"
                className={
                  errors.companyAbout
                    ? "input-textarea input-error-area"
                    : "input-textarea"
                }
                name="companyAbout"
                ref={register({ required: true })}
              />
            </fieldset>
          </div>

          <fieldset className="terms-field">
            <label htmlFor="ready-terms" className="ready-terms">
              <input
                type="checkbox"
                name="terms"
                id="ready-terms"
                ref={register({ required: true })}
                // onChange={() => setTermsChecked(!termsChecked)}
              />
              <p>
                Declaro que li e aceito os{" "}
                <Link to="/termos-de-utilizacao" state={{ modal: true }}>
                  Termos de Utilização
                </Link>
              </p>
            </label>
            {errors.terms && (
              <p className="error">Por favor aceite os termos de utilização!</p>
            )}
          </fieldset>

          <fieldset>
            <button type="submit" className="button-submit">
              <span>Salvar meu Perfil</span>
              <i></i>
            </button>
          </fieldset>
        </form>
      </ContainerDefault>
    </Container>
  );
}
